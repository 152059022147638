import { Link } from "gatsby";
import React from "react";
import { cn } from "../../lib/helpers";
import Icon from "../icon";
import styles from "./SearchListItem.module.scss";

const SearchListItem = ({ kommune, index, selectedIndex, inList }) => {
  return (
    <li className={cn(inList ? styles.inList : "", styles.listItem)}>
      <Link
        className={inList && selectedIndex === index ? styles.selected : ""}
        to={kommune.groupUrl || "/har-ikke-flyt-enda"}
        state={{ kommune }}
      >
        {kommune.name} <Icon icon="arrowRight" />
      </Link>
    </li>
  );
};

export default SearchListItem;
