import React, { createContext, useContext } from "react";
import useKommuneSearch from "../../hooks/useKommuneSearch";
import styles from "./KommuneSearch.module.scss";
import Input from "../Input/Input";
import { graphql, useStaticQuery } from "gatsby";
import { cn, mutateKommuneList } from "../../lib/helpers";
import TextLink from "../Link/Link";
import SearchListItem from "../SearchListItem/SearchListItem";

const KommuneSearchContext = createContext();

const SearchContainer = ({ children }) => {
  return <div className={styles.searchContainer}>{children}</div>;
};

const SearchDropdown = () => {
  const { results, dropdownOpen, selectedIndex } = useContext(
    KommuneSearchContext
  );
  return (
    <div className={cn(styles.searchDropdown, dropdownOpen ? styles.open : "")}>
      <ul>
        {results &&
          results.map((kommune, index) => (
            <SearchListItem
              kommune={kommune}
              index={index}
              selectedIndex={selectedIndex}
              key={`search-result-${kommune._id}`}
              inList
            />
          ))}
      </ul>
    </div>
  );
};

const SearchField = ({ placeholder }) => {
  const { query, search, dropdownOpen } = useContext(KommuneSearchContext);
  const updateQuery = (e) => {
    search(e.target.value);
  };
  return (
    <div
      className={cn(
        styles.searchField,
        dropdownOpen ? styles.dropdownOpen : ""
      )}
    >
      <Input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={updateQuery}
        icon="search"
      />
    </div>
  );
};

const KommuneSearch = ({ searchData }) => {
  const { placeholder, showAllKommunes } = searchData;
  let { allSanityGroup, allSanityKommune } = useStaticQuery(GET_ALL_GROUPS);
  const kommuneList = mutateKommuneList(
    allSanityKommune.edges,
    allSanityGroup.edges
  );
  return (
    <KommuneSearchContext.Provider value={useKommuneSearch(kommuneList)}>
      <div className={styles.kommuneSearchContainer}>
        <SearchContainer>
          <SearchField placeholder={placeholder} />
          <SearchDropdown kommuneList={kommuneList} />
        </SearchContainer>
        <TextLink to="/alle-kommuner">{showAllKommunes}</TextLink>
      </div>
    </KommuneSearchContext.Provider>
  );
};

const GET_ALL_GROUPS = graphql`
  query {
    allSanityGroup(sort: { fields: year, order: DESC }) {
      edges {
        node {
          year
          name
          id
          kommune {
            _id
            name
            city
          }
        }
      }
    }
    allSanityKommune {
      edges {
        node {
          name
          city
          _id
        }
      }
    }
  }
`;

export default KommuneSearch;
