import React from "react";
import styles from "./Input.module.scss";
import Icon from "../icon";

const Input = ({ label, placeholder, type, icon, ...props }) => {
  return (
    <div className={styles.inputFieldContainer}>
      <label>{label}</label>
      <input placeholder={placeholder} {...props} />
      <Icon icon={icon} size={18} />
    </div>
  );
};

export default Input;
