import React from "react";
import { cn } from "../../lib/helpers";
import Image from "../Image";
import styles from "./CircleImages.module.scss";

const CircleImages = ({ images, layout }) => {
  let circles = [];
  switch (layout) {
    case 1:
      circles = [null, images[0], images[1]];
      break;
    case 2:
      circles = [null, images];
      break;
    case 3:
      circles = images;
      break;
    default:
      break;
  }
  return (
    <div className={cn(styles.circleImages, styles[`layout${layout}`])}>
      {circles.map((image, index) =>
        image ? (
          <Image
            image={image}
            alt="Bilde fra Flyt-programmet"
            key={`circle-image-${index}`}
          />
        ) : (
          <div className={styles.filled} key={`circle-image-${index}`} />
        )
      )}
    </div>
  );
};

export default CircleImages;
