import React from "react";
import SEO from "../components/seo";
import KommuneSearch from "../components/KommuneSearch";
import Layout from "../components/Layout/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { Subtitle1 } from "../components/Typography/Typography";
import CircleImages from "../components/CircleImages/CircleImages";
import styles from "./pagestyles/IndexPage.module.scss";
import Quote from "../components/Quote/Quote";
import BlockContent from "../components/BlockContent/BlockContent";
import Link from "../components/Link/Link";

const IndexPage = ({ location }) => {
  const {
    sanityFlytLandingPage: {
      title,
      search,
      topImages,
      importantMessage,
      text1,
      firstImage,
      quote1,
      text2,
      bottomImages,
      quote2,
      text3,
    },
  } = useStaticQuery(LANDING_PAGE_QUERY);
  return (
    <Layout location={location}>
      <SEO title="Hjem" />
      <header className={styles.header}>
        <div className={styles.text}>
          <Subtitle1 className={styles.title}>{title}</Subtitle1>
          <KommuneSearch searchData={search} />
        </div>
        <CircleImages images={topImages} layout={1} />
      </header>
      <section className={styles.bottomContent}>
        {importantMessage && importantMessage.showImportantMessage && (
          <div className={styles.importantMessage}>
            <Subtitle1>{importantMessage.importantMessageTitle}</Subtitle1>
            <BlockContent blocks={importantMessage.importantMessage} />
            <Link to={importantMessage.link.href}>
              {importantMessage.link.name}
            </Link>
          </div>
        )}
        <div>
          <BlockContent blocks={text1} />
        </div>
        <div className={styles.twoColumn}>
          <CircleImages images={firstImage} layout={2} />
          <br />
          <div className={styles.quoteWrapper}>
            <Quote {...quote1} />
          </div>
        </div>
        <div>
          <BlockContent blocks={text2} />
          <br />
          <br />
          <Quote {...quote2} right />
          <br />
          <br />
          <CircleImages images={bottomImages} layout={3} />
          <br />
          <br />
          <BlockContent blocks={text3} />
        </div>
      </section>
    </Layout>
  );
};

const LANDING_PAGE_QUERY = graphql`
  query {
    sanityFlytLandingPage(_id: { regex: "/flytLandingPage$/" }) {
      title
      search: _rawSearch
      topImages {
        asset {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
        hotspot {
          x
          y
        }
      }
      importantMessage: _rawImportantMessage
      text1: _rawText1
      firstImage {
        asset {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
        hotspot {
          x
          y
        }
      }
      quote1 {
        text
        author
      }
      text2: _rawText2
      bottomImages {
        asset {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
        hotspot {
          x
          y
        }
      }
      quote2 {
        text
        author
      }
      text3: _rawText3
    }
  }
`;

export default IndexPage;
