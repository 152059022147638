import { useEffect, useReducer } from "react";
import { navigate } from "gatsby";
import { fuzzySearch } from "../components/KommuneSearch/searchUtils";

const useKommuneSearch = (kommuneList) => {
  const defaultSelectedIndexValue = -1;
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "NAVIGATE_WITH_ARROWS":
          const newValue = state.selectedIndex + action.value;
          if (newValue >= 0 && newValue < state.results.length) {
            let groupUrl = state.results[newValue].groupUrl;
            return {
              ...state,
              selectedIndex: newValue,
              selectedKommuneUrl: groupUrl ? groupUrl : "har-ikke-flyt-enda",
            };
          }
          return state;
        case "UPDATE_QUERY":
          return { ...state, query: action.value, results: [] };
        case "UPDATE_RESULTS":
          const dropdownOpen = action.value.length > 0;
          return {
            ...state,
            results: action.value ? action.value.slice(0, 4) : [],
            dropdownOpen: dropdownOpen,
            selectedIndex: dropdownOpen
              ? state.selectedIndex
              : defaultSelectedIndexValue,
          };
        case "NAVIGATE_TO_KOMMUNE":
          navigate(state.selectedKommuneUrl, {
            state: { kommune: state.results[state.selectedIndex] },
          });
          break;
        default:
          return state;
      }
    },
    {
      query: "",
      results: [],
      dropdownOpen: false,
      selectedIndex: defaultSelectedIndexValue,
      selectedKommuneUrl: "",
    }
  );

  useEffect(() => {
    const arrowListener = (e) => {
      if (e.key === "ArrowUp") {
        dispatch({ type: "NAVIGATE_WITH_ARROWS", value: -1 });
      } else if (e.key === "ArrowDown") {
        dispatch({ type: "NAVIGATE_WITH_ARROWS", value: 1 });
      } else if (e.key === "Enter") {
        dispatch({ type: "NAVIGATE_TO_KOMMUNE" });
      }
    };
    document.addEventListener("keydown", arrowListener);
    return () => document.removeEventListener("keydown", arrowListener);
  }, []);

  const setQuery = (value) => dispatch({ type: "UPDATE_QUERY", value });

  const search = (query) => {
    setQuery(query);
    let resultsArray = [];
    if (query.length >= 1) {
      resultsArray = fuzzySearch(query, kommuneList);
    }
    dispatch({ type: "UPDATE_RESULTS", value: resultsArray });
  };

  return { ...state, setQuery, search };
};

export default useKommuneSearch;
